.wrapper {
  background-color: white;
  height: 300px;
  width: 400px;
  margin: auto;
  margin-top: 3%;
  border-radius: 10px;
}
.search {
  display: flex;
  justify-content: center;
  width: 250px;
  margin: auto;
  margin-top: 5%;
  border-radius: 10px;
  padding: 3px;
}
input {
  border: none;
  height: inherit;
  width: 70%;
  padding: 7px;
  text-align: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
input:focus {
  outline: none;
}
button {
  display: inline-block;
  height: inherit;
  border: none;
  background-color: skyblue;
  font-weight: bold;
  font-size: 15px;
  padding: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* FIRST PART--LOGO */
.weatherLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 7rem;
  border-radius: 20px;
  background-color: white;
}
.logo {
  font-size: 70px;
}
.weather-info {
  background-color: aquamarine;
  height: 6rem;
  display: flex;
}
.temp {
  display: flex;
  width: 60%;
  background-color: black;
  color: white;
  height: inherit;
  align-items: center;
}
.time-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: aquamarine;
  width: 40%;
  margin-left: 0.5rem;
  color: white;
}
span {
  margin-right: 1rem;
}
.temp-data {
  margin-left: 0.5rem;
}

/* LAST SECTION */
last-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sunset,
.humidity,
.pressure,
.speed {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  height: 6rem;
}
.last-section {
  display: flex;
}
.image {
  margin-right: 0.5rem;
  color: aquamarine;
}
